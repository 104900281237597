<template>
  <div class="schemedetail">
    <div v-if="personalInfor.id!==''" class="detailtitle">
      <div class="titlebox">
        <div class="title">解决方案</div>
        <div class="banner">
          <span>您当前位置:&nbsp;&nbsp;&nbsp;</span>
          <span @click="gohome()">首页>&nbsp;</span>
          <span @click="goscheme()">解决方案</span>
        </div>
      </div>
    </div>
    <div class="detailtext">
      <div class="detailtext-box">
        <div class="detailtext-title">{{ personalInfor.schemeName }}</div>
        <div class="detailtext-content" style="text-indent: 2em;" v-html="personalInfor.programContent"></div>
      </div>
    </div>
    <div class="detailfooter"></div>
  </div>
</template>

<script>
import { getSolutionDetail } from '@/api/scheme/scheme.js'
export default {
  data() {
    return {
      schenmeid: '',
      personalInfor: {
        id: '',
        schemeName: '',
        programIntr: '',
        programId: '',
        programImg: '',
        programContent: ''
      }
    }
  },
  created() {
    this.schenmeid = this.$route.query.id
    console.log('this.$route.query.id', this.$route.query.id)
    getSolutionDetail(this.schenmeid).then((res) => {
      this.personalInfor = res.data.data
      console.log('personalInfor', this.personalInfor)
    })
  },
  methods: {
    // 去首页
    gohome() {
      this.$router.push('index')
    },
    // 去方案页
    goscheme() {
      this.$router.push('/scheme')
    }
  }
}
</script>

<style lang="less" scoped>
.schemedetail{
  background-image: url('../../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 100% 100%;
  .detailtitle{
    width: 100%;
    height: 182px;
    background: url(../../assets/detailBg.png) 0% 0% / 100% no-repeat;
    // text-align: center;
    .titlebox{
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      color: white;
      padding-top: 60px;
      .title{
        font-size: 24px;
        margin-bottom: 16px
      }
      .banner{
        color: #e9e9eb;
        font-size: 12px;
        span:nth-of-type(2){
          cursor: pointer;
        }
        span:nth-of-type(3){
          cursor: pointer;
        }
      }
    }
  }
  .detailtext{
    min-height: 556px;
    width: 100%;
    .detailtext-box{
      margin: 0 auto;
      width: 1200px;
      .detailtext-title{
        margin-top: 54px;
        font-size: 32px;
        text-align: center;
      }
      .detailtext-content{
        margin-top: 38px;
        p{
          margin-top: 0px;
          margin-bottom: 16px;
          font-family: 微软雅黑;
          white-space: normal;
          background-repeat: no-repeat;
          padding: 0px;
          text-indent: 2em;
          color: rgb(51, 51, 51);
          line-height: 28px;
          font-size: 15px;
          background-color: rgb(255, 255, 255);
        }
      }
    }
  }
  .detailfooter{

  }
}
</style>
